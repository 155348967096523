import {createGlobalStyle} from "styled-components";
const GlobalStyle = createGlobalStyle`
  //reset from: https://www.joshwcomeau.com/css/custom-css-reset/
  *, *::before, *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
    @media screen and
      (prefers-reduced-motion: reduce), 
      (update: slow) {
        animation-duration: 0.001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.001ms !important;
    }
  }
  :root {
    font-size: calc(0.625 * 100%);
  }
  html, body {
    height: 100%;
  }
  body {
    background: #f4f4f4;
    font-size:0.625rem;
    line-height: 1.5;
    color: #222;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif;
  }
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }
  input, button, textarea, select {
    font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
    font-size: 1.6rem;
    overflow-wrap: break-word;
  }
  #root, #__next {
    isolation: isolate;
  }
  
`;
export default GlobalStyle;