import React, {useEffect, useState} from "react"
import subjects from '../data/subjects.json';
import twists from '../data/twists.json';
import styled from "styled-components";
import Logo from '../components/logo';
import GlobalStyle from '../utils/global';
import Helmet from "react-helmet";
import Loading from "../components/loading";
import Placeholder from "../images/placeholder.png";
import Image from "../components/image";

const Page = styled.div`
`;

const HeaderNav = styled.div`
    background: #fff;
    height: 40px;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    margin-bottom: 50px;
`;
const MainSection = styled.div`
  padding: 0 4rem;
`;
const Article = styled.div`
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    max-width: 1400px;
    margin:0 auto;
    @media screen and (min-width: 980px){
      grid-template-columns: 45% 45%;
      grid-gap: 10%;
    }
`;
const Headline = styled.h1`
  font-size: 3.2rem;
  margin-top: 30px;
  @media screen and (min-width: 500px){
    font-size:4.2rem;
    margin-top:0;
  }
`
const BodyText = styled.p`
  font-size: 18px;
  line-height: 1.9;
  margin-top: 20px;
  grid-column: 1 / -1;
  white-space: pre-line;
`;
const getNonRepeated = (data, localName) => {
  let chosenOne = randomEntry(data);
  while (localStorage.getItem(localName) === data[chosenOne]) {
    chosenOne = randomEntry(data);
  }
  return chosenOne;
}
const getData = (dataSet, dataType) => {
    const chosenOne = getNonRepeated(dataSet.data, `last${dataType}`)
    localStorage.setItem(`last${dataType}`, dataSet.data[chosenOne]);
    return dataSet.data[chosenOne];
};
const randomIncluisve = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
const randomEntry = (inputArray) => randomIncluisve(0, inputArray.length - 1);
const IndexPage = () => {
  const [photo, setPhotosResponse] = useState({
    urls:{
      regular: Placeholder
    },
    alt_description: 'placeholder',
    user: {
      username: '',
    }
  });
  const [photoLoaded, setPhotoLoaded] = useState(false);
  const [number, setNumber] = useState(null);
  const [subject, setSubject] = useState(null);
  const [twist, setTwist] = useState(null);
  const [text, setText] = useState(null);
  useEffect(()=>{
    async function fetchText() {
      if(!subject) {
        return false;
      }
      try {
        let response = await fetch(`/api/get-text?search=${number} reasons ${subject} are ${twist}`);
        if (response.ok) {
          let json = await response.json();
          setText(json);
        }
      } catch (error) {
      }

    }
    fetchText();
  }, [subject])
  useEffect(() => {
    async function fetchPhoto() {
      if(!subject) {
        return false;
      }
      try {
        let response = await fetch(`/api/get-image?search=${subject}`);
        if (response.ok) {
          let json = await response.json();
          setPhotosResponse(json);
          setPhotoLoaded(true)
        }
      } catch (error) {
      }

    }
    fetchPhoto();
  }, [subject]);
  useEffect(() => {
    setNumber(randomIncluisve(7, 23));
    setSubject(getData(subjects, 'subject'));
    setTwist(getData(twists, 'twist'));
  }, []);
  return (
    <Page>
    <Helmet>
      <title>Reasons To List</title>
      <meta name="description" content="Grab life by the listicles." />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <link rel="canonical" href="http://www.reasonstolist.com/" />
    </Helmet>
      <GlobalStyle />
      <HeaderNav>
        <Logo />
      </HeaderNav>
      <MainSection>
        <Article>
          <Image photoLoaded={photoLoaded} photo={photo} />
          <Headline>
            {number} reasons {subject} are {twist}.
          </Headline>
          <BodyText>
            {text && text}
            {!text && <Loading>Loading Article...</Loading>}
          </BodyText>
        </Article>
      </MainSection>
    </Page>
)};

export default IndexPage
