import React, { useMemo } from "react"
import styled, { keyframes } from "styled-components"
interface LoadingProps {
  children: string;
}
interface SCProps { 
  $count: number;
}
interface BumperProps {
  $timingoffset: number,
  $count: number;
  $space: boolean;
}
const bump = keyframes`
  0% {
    transform: translateY(0px);
    text-shadow: none;
  }
  25% {
    transform: translateY(-10px);
    text-shadow: 0px 19px 3px #999999;
  }
  50% {
    transform: translateY(0px) scale(1);
    text-shadow: none;
  }  
`;
const StyledLoader = styled.strong<SCProps>`
    animation-timing-function: ease;
`;
const BumperCrop = styled.span<BumperProps>`
    animation-name: ${bump};
    animation-duration: ${({$count}) => `${$count/10}s`};
    animation-iteration-count: infinite;
    animation-timing-function: inherit;
    display: inline-block;
    animation-delay: ${({$timingoffset}) => `${$timingoffset/10}s`};
    width: ${({$space})=>$space ? '8px' : 'auto'};
`;

const Loading = ({children}:LoadingProps) => {
  
  const wordLength = useMemo(()=>(children.split("").length), [children]);
  const splitLetters = useMemo(()=>(
    children.split("").map((letter, i) => <BumperCrop aria-hidden="true" $count={wordLength} $space={letter === ' '} $timingoffset={i} key={`${letter}-${i}`}>{letter}</BumperCrop>)
  ), [children]);
  return <StyledLoader role="marquee" aria-label={children} $count={wordLength}>{splitLetters}</StyledLoader>
}
export default Loading;