import React from "react"
import styled, {keyframes, css} from "styled-components";
interface ImageProps {
  photoLoaded: boolean;
  photo: {
    urls: {
      regular: string;
    };
    alt_description: string;
    user: {
      username: string;
    }
  };
}
const background = keyframes`
  0% {
    background-color: #565656;
  }
  50% {
    background-color: #b71e4a;
  }
  100% {
    background-color: #565656;
  }
`
const ImageWrapper = styled.div<ImageProps>`
  position: relative;
  aspect-ratio: 2/1.25;  
  width: 100%;
  ${({photoLoaded}) =>
    !photoLoaded && 
    css`
      animation-name: ${background};
      animation-duration: 10s;
      animation-iteration-count: infinite;
    `
  }
`;
const ShareImage = styled.img`
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
const ImageNotes = styled.div`
    height: 100%;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (min-width:500px){
      background: rgb(244 244 244 / 80%);
      height: auto;
      flex-direction: row;
    }
`
const Attribution = styled.p`
  font-size: 12px;
  a {
    color: #b71e49;
    &:hover{
      text-underline-offset: 2px;
    }
  }
  background: rgb(255 255 255 / 61%);
  text-align: center;
  @media screen and (min-width: 500px) {
    text-align: left;
    background: none;
  }
`;
const Trending = styled.div`
  color: #b71e49;
  font-size: 1.5rem;
  position: relative;
  top: -30px;
  @media screen and (min-width: 500px) {
    top: 0;
  }
`
const Icon = styled.svg`
    width: 1.5rem;
    max-height: 1.5rem;
    fill: #b71e49;
    display: inline-block;
    margin-right: 4px;
    vertical-align: text-bottom;
`;
const Image = ({photoLoaded, photo}:ImageProps) => (
<ImageWrapper photoLoaded={photoLoaded} photo={photo}>
  <ShareImage src={`${photo.urls.regular}${photoLoaded ? '&crop=edges,faces&h=400' : ''}`} alt={photo.alt_description} />
  <ImageNotes>
    <Trending>
      <Icon viewBox="0 0 512 512" aria-hidden="true">
        <path d="M480.026 20l-212.328 85.658 84.162 48.587-79.503 137.7-137.693-79.502L-1 447.418l75.985 43.874 91.79-158.99 137.7 79.495L427.853 198.12l84.146 48.586L480.026 20z" fillRule="evenodd"></path>
      </Icon> Trending Right Now!!!
    </Trending>
    {photo.user.username !== '' && <Attribution>Photo by <a href={`https://unsplash.com/@${photo.user.username}?utm_source=reasons_to_list&utm_medium=referral`} rel="noreferrer" target="_blank">{photo.user.username}</a> on <a href="https://unsplash.com" rel="noreferrer" target="_blank">Unsplash</a></Attribution>}
  </ImageNotes>
</ImageWrapper>
);

export default Image;